<template>
  <v-container fluid>
    <v-row>
      <v-col cols="3">

      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tabs v-model="tab">
          <v-tab key="colors">{{ $t("designer.colors") }}</v-tab>
          <v-tab v-if="roles.indexOf('colors.edit') > -1" key="palettes">{{ $t("designer.palettes") }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item key="colors">
            <v-dialog v-model="dialog" max-width="600px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ $t("designer.color") }}</span>
                  <v-spacer></v-spacer>
                  <v-switch
                    v-model="editItem.is_deleted"
                    :label="$t('common.hidden')"
                  ></v-switch>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editItem.title"
                                      :label="$t('common.name')" required></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editItem.id"
                                      label="ID" required></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editItem.sort"
                                      :label="$t('common.sort')"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editItem.weight"
                                      :label="$t('common.weight')"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editItem.hex"
                                      :label="$t('common.hex')"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="1" md="1">
                        <div
                          :style="'width: 50px;height: 50px;background-color:#' + editItem.hex"></div>
                      </v-col>
                      <v-col cols="12">
                        <label>SVG Path
                          <v-textarea rows="3" v-model="editItem.svg"></v-textarea>
                        </label>
                      </v-col>
                      <v-col cols="12">
                        <label>{{ $t('common.file') }}
                          <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
                        </label>
                      </v-col>
                      <!--                    <v-col cols="6" sm="6" md="6">-->
                      <!--                      <label>{{ $t('common.file_letter') }}-->
                      <!--                        <input type="file" id="file_letter" ref="file_letter" v-on:change="handleFileLetterUpload()"/>-->
                      <!--                      </label>-->
                      <!--                    </v-col>-->
                      <v-select
                        v-model="editItem.palettes"
                        :items="palettes"
                        :menu-props="{ maxHeight: '400' }"
                        :label="$t('designer.palettes')"
                        chips
                        multiple
                        :hint="$t('designer.select_palettes')"
                        persistent-hint
                        item-text="title"
                        item-value="_id"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item
                            ripple
                            @click="toggePalettes"
                          >
                            <v-list-item-action>
                              <v-icon
                                :color="editItem.palettes.length > 0 ? 'indigo darken-4' : ''">
                                {{ editSelectAllicon }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>{{ $t("common.select_all") }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                      </v-select>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialog = false">{{ $t("common.cancel") }}</v-btn>
                  <v-btn color="blue darken-1" text @click="saveColor">{{ $t("common.save") }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-data-table :headers="headers" :items="filteredColors" class="elevation-1"
                          :disable-pagination="true" :disable-sort="true" item-key="id"
                          hide-default-footer v-if="colors">
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-toolbar-title>{{ $t("designer.colors") }}</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>
                  <v-select
                    v-model="currentPalette"
                    v-if="palettes"
                    :items="palettes"
                    item-text="title"
                    item-value="_id"
                    label="Palette"
                    hide-details
                    solo
                  ></v-select>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>
                  <v-spacer></v-spacer>
                  <v-btn v-if="roles.indexOf('colors.edit') > -1" depressed color="primary" @click="createColor">{{ $t("designer.new_color") }}</v-btn>
                </v-toolbar>
              </template>
              <template v-slot:item.preview_url="{ item }">
                <v-img :src="item.preview_url" max-width="50px"/>
              </template>
              <template v-slot:item.title="{ item }">
                <strong>{{ item.title }}</strong> <span
                style="font-size: 11px;color: gray;">/{{ item.id }}</span>
              </template>
              <template v-slot:item.hex="{ item }">
                <div :style="'width: 50px;height: 50px;border:1px solid gray; background-color:#' + item.hex"></div>
              </template>
              <template v-slot:item.is_deleted="{ item }">
                <v-icon :disabled="roles.indexOf('colors.edit') == -1" @click="toggleIsEnabled(item)">fas {{ getIsActiveIconName(item) }}</v-icon>
              </template>
              <template v-slot:item.palettes="{ item }">
                <v-chip x-small v-for="palette in item.palettes" v-if="palettesMap[palette] != undefined">
                  {{ palettesMap[palette].title }}
                </v-chip>
              </template>
              <template v-slot:item.hex_value="{ item }">
                {{ item.hex }}
              </template>
              <template v-slot:item.svg="{ item }">
                <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Artboard" :fill="'#' + item.hex" v-html="item.svg">
                    </g>
                  </g>
                </svg>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  v-for="action in item.actions"
                  @click="applyAction(action.type, item)">
                  {{ action.icon }}
                </v-icon>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item key="palettes">
            <v-dialog v-model="paletteDialog" max-width="600px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ $t("designer.palette") }}</span>
                  <v-spacer></v-spacer>
                  <!--                  <v-switch-->
                  <!--                    v-model="editItem.is_deleted"-->
                  <!--                    :label="$t('common.hidden')"-->
                  <!--                  ></v-switch>-->
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editItem.title"
                                      :label="$t('common.name')" required></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editItem.sort"
                                      :label="$t('common.sort')"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialog = false">{{ $t("common.cancel") }}</v-btn>
                  <v-btn color="blue darken-1" text @click="savePalette">{{ $t("common.save") }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-data-table :headers="paletteHeaders" :items="palettes" class="elevation-1"
                          :disable-pagination="true" :disable-sort="true" item-key="_id"
                          hide-default-footer v-if="palettes">
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-toolbar-title>{{ $t("designer.palettes") }}</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                  ></v-divider>

                  <v-spacer></v-spacer>
                  <v-btn depressed color="primary" @click="createPalette">{{ $t("common.new") }}</v-btn>
                </v-toolbar>
              </template>
              <template v-slot:item.title="{ item }">
                <strong>{{ item.title }}</strong>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  v-for="action in item.actions"
                  @click="paletteApplyAction(action.type, item)">
                  {{ action.icon }}
                </v-icon>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
  name: "AdminColors",
  data() {
    return {
      tab: null,
      dialog: false,
      paletteDialog: false,
      currentPalette: null,
      editItemFile: '',
      editItem: {},
      palettesMap: {},
      paletteHeaders: [{
        text: this.$t('common.name'),
        value: 'title',
        width: '120px',
      }, {
        text: '',
        value: 'actions',
      },],
      headers: [
        {
          text: '',
          align: 'left',
          sortable: false,
          value: 'preview_url',
          width: '60px',
        },
        {
          text: this.$t('common.preview'),
          value: 'hex',
          width: '70px',
        },
        {
          text: this.$t('common.name'),
          value: 'title',
          width: '120px',
        },
        {
          text: this.$t('designer.color'),
          value: 'hex_value',
          width: '60px',
        },
        {
          text: this.$t('common.is_active'),
          value: 'is_deleted',
          width: '60px',
        },
        {
          text: this.$t('designer.palettes'),
          value: 'palettes',
          width: '60px',
        },
        {
          text: 'SVG',
          value: 'svg',
          width: '60px',
        },
        {
          text: '',
          value: 'actions',
        },
      ],
    }
  },
  computed: {
    ...mapState('colors', ['colors', 'palettes']),
    ...mapState('app', ['isLoading', 'roles']),
    filteredColors() {
      if (this.colors) {
        return this.colors.filter(x => x.palettes.includes(this.currentPalette));
      }
      return []
    },
    editSelectAllicon() {
      if (this.editItem.palettes.length == this.palettes.length) return 'fas fa-check-square'
      if (this.editItem.palettes.length > 0) return 'fas fa-minus-square'
      return 'far fa-square'
    },
  },
  async mounted() {
    await this.reloadData()
  },
  methods: {
    ...mapActions('colors', ['loadColors']),
    toggePalettes() {
      this.$nextTick(() => {
        if (this.editItem.palettes.length == this.palettes.length) {
          this.editItem.palettes = []
        } else {
          this.editItem.palettes = this.palettes.map(function (item) {
            return item._id;
          });
        }
      })
    },
    async reloadData() {
      this.$store.commit('app/setLoading', true)
      await this.loadColors({skipLoadingImages: true, reload: true})
      this.palettesMap = {}
      for (let item of this.palettes) {
        this.palettesMap[item._id] = item;
      }
      if (this.currentPalette == null) {
        this.currentPalette = this.palettes[0]._id
      }
      this.$store.commit('app/setLoading', false)
    },
    handleFileUpload() {
      this.editItemFile = this.$refs.file.files[0];
    },
    async saveColor() {
      let formData = new FormData();
      formData.append('file', this.editItemFile);
      formData.append('color', JSON.stringify(this.editItem));
      await this.$api.colors.save(formData);
      this.dialog = false;
      await this.reloadData()
    },
    async savePalette() {
      let formData = new FormData();
      formData.append('palette', JSON.stringify(this.editItem));
      await this.$api.colors.savePalette(formData);
      this.paletteDialog = false;
      await this.reloadData()
    },
    createColor() {
      this.editItem = {
        _id: null,
        id: 1,
        title: "",
        sort: 0,
        weight: 0,
        file_name: "",
        hex: "",
        svg: "",
        is_deleted: false,
        palettes: ['5fdf90c8b24c63b003bf3ba7'],
      }
      this.dialog = true
    },
    createPalette() {
      this.editItem = {
        _id: null,
        title: "",
        sort: 0,
      }
      this.paletteDialog = true
    },

    applyAction(actionType, item) {
      if (actionType == 'edit') {
        this.editItemAction(item)
      }
    },
    paletteApplyAction(actionType, item) {
      if (actionType == 'edit') {
        this.paletteEditItemAction(item)
      }
    },
    editItemAction(item) {
      if (this.$refs.file != undefined) {
        this.$refs.file.value = '';
      }

      if (this.$refs.file_letter != undefined) {
        this.$refs.file_letter.value = '';
      }

      this.editItemFile = ''
      this.editItem = Object.assign({}, item);
      this.dialog = true
    },
    paletteEditItemAction(item) {
      this.editItemFile = ''
      this.editItem = Object.assign({}, item);
      this.paletteDialog = true
    },
    getIsActiveIconName(item) {
      if (item.is_deleted) {
        return "fa-toggle-off"
      } else {
        return "fa-toggle-on"
      }
    },
    async toggleIsEnabled(item) {
      if (item.is_deleted) {
        item.is_deleted = false;
      } else {
        item.is_deleted = true;
      }

      let formData = new FormData();
      formData.append('color', JSON.stringify(item));
      await this.$api.colors.save(formData);
    }
  }
}
</script>

<style scoped>

</style>
